import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Seo from '../components/seo/Seo';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Container from '../components/Container';
import BlogCard from '../components/BlogCard';

const Blog = ({
  data: { site, allMdx },
  pageContext: { pagination, categories },
}) => {
  const { page, nextPagePath, previousPagePath } = pagination;

  const posts = page
    .map(id => allMdx.edges.find(edge => edge.node.id === id))
    .filter(post => post !== undefined);

  return (
    <Layout site={site} darkNav>
      <Seo />
      <div className="bg-gray-100 sm:px-8 py-8 flex-grow">
        <Container className="pt-8">
          {posts.map(({ node: post }) => (
            <BlogCard key={post.id} post={post} fluidImage />
          ))}
          <div className="mb-8 mt-16 text-center">
            {nextPagePath && (
              <Link to={nextPagePath} aria-label="View next page">
                Next Page →
              </Link>
            )}
            {previousPagePath && (
              <Link to={previousPagePath} aria-label="View previous page">
                ← Previous Page
              </Link>
            )}
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      ...site
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { isPost: { eq: true } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          id
          fields {
            title
            slug
            date
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            banner {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            slug
            keywords
          }
        }
      }
    }
  }
`;
